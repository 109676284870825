<template>
    <div class="deal-error">
        <div class="deal-error_icon" >
            <b-icon scale="1.5" icon="x"></b-icon>
        </div>
        <h3 v-html="$content('invest_failed')"></h3>
        <div v-if="params.errors">
            <div v-for="(list, key) in params.errors">
                <p v-for="item in list">{{ item }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DealError",
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        }
    },
    destroyed() {
        this.$store.dispatch('exchanges.errors');
    }
}
</script>

<style lang="scss" scoped>
    .deal-error {
        background: white;
        padding: 40px 60px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: red;
        font-family: Montserrat-Medium, sans-serif;

        &_icon {
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
            flex-shrink: 0;
            background: red;

            & svg {
                color: white;
            }
        }

        & p {
            text-align: center;
        }

        & h3 {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 25px;
            margin: 20px 0 15px 0;
            text-align: center;
        }
    }
</style>